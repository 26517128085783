import { useState, useMemo, useCallback } from "react"

export const useLocalStorageState = <T,>(
	storageKey: string
): [T | undefined, (value: T | undefined) => void] => {
	const [storageValue, setStorageValue] = useState(
		localStorage.getItem(storageKey)
	)

	const parsedValue = useMemo(
		() => (storageValue ? (JSON.parse(storageValue) as T) : undefined),
		[storageValue]
	)
	const setObjectValue = useCallback(
		(value: T | undefined) => {
			if (!value) {
				setStorageValue(null)
				localStorage.removeItem(storageKey)
				return
			}

			const json = JSON.stringify(value)
			setStorageValue(json)
			localStorage.setItem(storageKey, json)
		},
		[storageKey]
	)

	return [parsedValue, setObjectValue]
}
