import { tenantRegistry } from "mco-tenant-configurations"
import tenantSpecificationRegistry from "mco-tenant-specifications"
import { useCallback, useEffect, useState } from "react"
import {
	Routes,
	Route,
	useNavigate,
	useLocation,
	useParams
} from "react-router-dom"
import { useDispatch } from "react-redux"
import { Container } from "react-bootstrap"
import {
	tenantUpdated,
	updateTenantEnvironment
} from "../../state/environment/actions"
import { TENANT_ENVIRONMENT_RECEIVE } from "../../state/environment/types"
import TenantContext, {
	RadminTenant,
	RadminTenantEnvironment
} from "./TenantContext"
import { useTenantSubDomain } from "./TenantHooks"
import TenantSelector from "./TenantSelector"
import LoadingMessage from "../layout/LoadingMessage"
import useAuthenticationContext from "../authentication/AuthenticationContext"

function useRedirectIfOnUserDetails() {
	const navigate = useNavigate()
	const location = useLocation()

	return useCallback(() => {
		const otherProfessionalSubdirectories: string[] = [
			"add",
			"bulkadd",
			"sharing"
		]
		const otherPatientSubdirectories: string[] = ["events", "issues"]
		const path: string[] = location.pathname.split("/")
		if (
			(path[1] === "professionals" &&
				!otherProfessionalSubdirectories.includes(path[2])) ||
			(path[1] === "patients" &&
				!otherPatientSubdirectories.includes(path[2]))
		) {
			navigate(`${path[1]}/search`)
		}
	}, [location.pathname, navigate])
}

function TenantProvider({ children }: { children: React.ReactNode }) {
	const { logout: handleLogout } = useAuthenticationContext()
	const navigate = useNavigate()
	const redirectIfOnUserDetails = useRedirectIfOnUserDetails()
	const dispatch = useDispatch()
	const tenantSubDomain = useTenantSubDomain()
	const { tenantSubDomain: tenantSubDomainFromUri } = useParams()

	const [tenant, setTenant] = useState<RadminTenant | undefined>(undefined)
	const [error, setError] = useState("")

	useEffect(() => {
		if (!tenantSubDomain) {
			if (tenantSubDomainFromUri) {
				navigate("/")
			}

			setTenant(undefined)
			return
		}

		if (tenantSubDomain === tenant?.configuration.subdomain) {
			return
		}

		const loadTenant = async () => {
			const t = await tenantRegistry.getTenantBySubDomain(tenantSubDomain)

			const spec =
				await tenantSpecificationRegistry.getTenantBySubDomain(
					tenantSubDomain
				)

			const environmentResult = await dispatch(updateTenantEnvironment())

			if (environmentResult.type !== TENANT_ENVIRONMENT_RECEIVE) {
				setError(
					`Unable to load tenant details. Check the API is running and reload.`
				)
				console.error(environmentResult)
				return
			}

			const environment =
				environmentResult.payload as RadminTenantEnvironment

			const newTenant = {
				configuration: t,
				specification: spec,
				environment
			}

			redirectIfOnUserDetails()
			dispatch(tenantUpdated())
			setTenant(newTenant)
		}

		loadTenant()
	}, [
		dispatch,
		navigate,
		redirectIfOnUserDetails,
		tenant?.configuration.subdomain,
		tenantSubDomain,
		tenantSubDomainFromUri
	])

	if (error) {
		return (
			<Container>
				<h1>{error}</h1>
				<button className="btn btn-primary" onClick={handleLogout}>
					Logout
				</button>
			</Container>
		)
	}

	if (!tenant) {
		return <LoadingMessage>Loading tenant...</LoadingMessage>
	}

	return (
		<TenantContext.Provider value={{ tenant, tenantSubDomain }}>
			{children}
		</TenantContext.Provider>
	)
}

function TenantRouteHandler({ children }: { children: React.ReactNode }) {
	return (
		<Routes>
			<Route
				path="/tenant/:tenantSubDomain/*"
				element={<TenantProvider>{children}</TenantProvider>}
			/>
			<Route path="*" element={<TenantSelector />} />
		</Routes>
	)
}

export default TenantRouteHandler
