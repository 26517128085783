import { useState, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Nav, Button, ButtonGroup } from "react-bootstrap"
import { updateProfessionalsSearchQuery } from "../../state/professionals/search/actions"
import { updatePatientsSearchQuery } from "../../state/patients/search/actions"
import McoLogo from "../../images/mco-logo.svg?react"
import ZoneBadge from "./ZoneBadge"
import { State } from "../../state/reducer"
import TenantContext from "../tenancy/TenantContext"
import { Link } from "react-router-dom"
import { useNickname } from "../../hooks/useNickname"
import { useTenantSelector } from "../tenancy/TenantHooks"
import TimeTravelBadge from "./TimeTravelBadge"
import TenantShutdownBadge from "./TenantShutdownBadge"
import useAuthenticationContext from "../authentication/AuthenticationContext"

function TenancySelector() {
	const { tenant } = useContext(TenantContext)
	const selectTenant = useTenantSelector()
	return (
		<Button className="col-md-8 px-0" onClick={selectTenant}>
			{tenant?.configuration.internalName || "No tenant selected"}
		</Button>
	)
}

function TopBar() {
	const { logout: handleLogout } = useAuthenticationContext()
	const professionalsSearchQuery = useSelector(
		(state: State) => state.professionals.search.searchQuery
	)
	const patientsSearchQuery = useSelector(
		(state: State) => state.patients.search.searchQuery
	)
	const [searchTargetProfessionals, setSearchTargetProfessionals] =
		useState(false)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const nickname = useNickname()

	const handleSearchTargetProfessionalsClick = () =>
		setSearchTargetProfessionals(true)
	const handleSearchTargetPatientsClick = () =>
		setSearchTargetProfessionals(false)

	const handleSearchQueryUpdate = (e: any) => {
		if (searchTargetProfessionals) {
			dispatch(updateProfessionalsSearchQuery(e.target.value))
		} else {
			dispatch(updatePatientsSearchQuery(e.target.value))
		}
	}
	const handleKeyPress = (e: any) => {
		if (e.keyCode === 13) {
			const destination = searchTargetProfessionals
				? "professionals/search"
				: "patients/search"
			navigate(destination)
		}
	}

	const searchQuery = searchTargetProfessionals
		? professionalsSearchQuery
		: patientsSearchQuery

	return (
		<header className="top-bar navbar-dark sticky-top bg-dark text-light p-0 navbar navbar-expand navbar-dark flex-column flex-md-row bd-navbar">
			<Nav className="col-md-2 mx-0 pr-0 align-items-center navbar-nav flex-row">
				<div className="col-md-4 px-2 ">
					<Link className="navbar-brand" to="">
						<McoLogo style={{ height: 40 }} />
					</Link>
				</div>
				<TenancySelector />
			</Nav>
			<ZoneBadge />
			<TenantShutdownBadge />
			<TimeTravelBadge />
			<Nav
				className="px-3 align-items-center navbar-nav flex-row ml-md-auto d-none d-md-flex"
				as="ul"
			>
				<Nav.Item as="li" className="px-1 text-nowrap">
					Hi {nickname}
				</Nav.Item>
				<Nav.Item as="li" className="px-1 text-nowrap">
					<ButtonGroup
						aria-label="Select user type for searching"
						className="pr-1"
					>
						<Button
							variant={
								searchTargetProfessionals ? "secondary" : "info"
							}
							onClick={handleSearchTargetPatientsClick}
						>
							Patient
						</Button>
						<Button
							variant={
								searchTargetProfessionals ? "info" : "secondary"
							}
							onClick={handleSearchTargetProfessionalsClick}
						>
							Professional
						</Button>
					</ButtonGroup>
				</Nav.Item>
				<Nav.Item as="li" className="px-1 text-nowrap">
					<input
						type="text"
						name="search"
						placeholder="User Search "
						className="form-control"
						style={{ display: "inline-block", width: "auto" }}
						value={searchQuery}
						onChange={handleSearchQueryUpdate}
						onKeyUp={handleKeyPress}
					/>
				</Nav.Item>
				<Nav.Item as="li" className="px-1 text-nowrap">
					<button className="btn btn-primary" onClick={handleLogout}>
						Logout
					</button>
				</Nav.Item>
			</Nav>
		</header>
	)
}

export default TopBar
