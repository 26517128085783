const CenteredContainer = ({
	children
}: {
	children: JSX.Element | (JSX.Element | false)[]
}) => (
	<div
		style={{
			display: "flex",
			textAlign: "center",
			minHeight: "100vh",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center"
		}}
	>
		{children}
	</div>
)

export default CenteredContainer
