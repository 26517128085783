import { combineReducersWithRequestValidation } from "../middleware/RequestValidationMiddleware"
import assessmentsReducer, { AssessmentsState } from "./assessments/reducer"
import auditReducer, { AuditState } from "./audit/reducer"
import clientReducer, { ClientState } from "./client/reducer"
import environmentReducer, { EnvironmentState } from "./environment/reducer"
import extractsReducer, { ExtractsState } from "./extracts/reducer"
import integrationsReducer, { IntegrationsState } from "./integrations/reducer"
import patientsReducer, { PatientsState } from "./patients/reducer"
import professionalsReducer, {
	ProfessionalsState
} from "./professionals/reducer"
import templatesReducer, { TemplatesState } from "./templates/reducer"

export interface State {
	audit: AuditState
	assessments: AssessmentsState
	templates: TemplatesState
	client: ClientState
	environment: EnvironmentState
	extracts: ExtractsState
	integrations: IntegrationsState
	patients: PatientsState
	professionals: ProfessionalsState
}

const rootReducer = () =>
	combineReducersWithRequestValidation({
		audit: auditReducer,
		assessments: assessmentsReducer,
		templates: templatesReducer,
		client: clientReducer,
		environment: environmentReducer,
		extracts: extractsReducer,
		integrations: integrationsReducer,
		patients: patientsReducer,
		professionals: professionalsReducer
	})

export default rootReducer
