import { Badge } from "react-bootstrap"
import "../../style/style.scss"
import { zoneRegistry } from "mco-zone-configurations"

const ZoneBadge = () => {
	const {
		codename,
		settings: { cssClass }
	} = zoneRegistry.get()
	return (
		<h2 className="mt-1 mx-1">
			<Badge className={cssClass}>Zone: {codename}</Badge>
		</h2>
	)
}

export default ZoneBadge
