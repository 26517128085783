/// <reference types="google.accounts" />
import {
	createContext,
	useCallback,
	useContext,
	useLayoutEffect,
	useRef,
	useState
} from "react"
import { DateTime } from "luxon"
import McoLogo from "../../images/mco-logo.svg?react"
import BrowserWarning from "../BrowserWarning"
import { useSelector } from "react-redux"
import type { State } from "../../state/reducer"
import {
	getApiUrl,
	unauthorizedApiRequestEventType
} from "../../middleware/ApiRequestMiddleware"
import { parseJwt } from "./parseJwt"
import type {
	AuthenticationContext,
	CredentialDetails,
	DecodedAccessToken,
	TokenResponse
} from "./AuthenticationTypes"
import { useCredentialFromStorage } from "./AuthenticationStorage"
import googleSignInSettings from "./googleSignInSettings"
import { zoneRegistry } from "mco-zone-configurations"
import { toast, ToastOptions } from "react-toastify"
import CenteredContainer from "../layout/CenteredContainer"
import LoadingMessage from "../layout/LoadingMessage"

const toastOptions: ToastOptions = {
	position: "top-left"
}

const Context = createContext(undefined as unknown as AuthenticationContext)

function getCredentialDetails(
	credential: string | undefined
): CredentialDetails {
	if (!credential) {
		return {
			isAuthenticated: false
		}
	}

	const decodedCredential = parseJwt<DecodedAccessToken>(credential)
	const expiresAt = DateTime.fromMillis(decodedCredential.exp)

	if (expiresAt > DateTime.now()) {
		console.log("Credential has expired")
		return {
			isAuthenticated: false
		}
	}

	return {
		isAuthenticated: true,
		email: decodedCredential.email,
		name: decodedCredential.given_name,
		picture: decodedCredential.picture
	}
}

function SignInPage({
	setFetchingTokens,
	setCredential
}: {
	setFetchingTokens: (fetchingTokens: boolean) => void
	setCredential: (value: TokenResponse | undefined) => void
}) {
	return (
		<CenteredContainer>
			<McoLogo
				style={{
					height: "20vmin",
					pointerEvents: "none"
				}}
			/>
			<BrowserWarning />
			<GoogleSignIn />
			<DeveloperSignIn
				setFetchingTokens={setFetchingTokens}
				setCredential={setCredential}
			/>
		</CenteredContainer>
	)
}

function GoogleSignIn() {
	const signInButtonRef = useRef<HTMLDivElement>(null)

	useLayoutEffect(() => {
		if (!signInButtonRef.current) {
			return
		}

		console.debug("Rendering Google sign-in button")
		google.accounts.id.renderButton(signInButtonRef.current, {
			type: "standard",
			theme: "outline",
			size: "large"
		})

		console.log("Displaying one tap prompt")
		google.accounts.id.prompt()
	}, [])

	return <div id="googleSignInButton" ref={signInButtonRef} />
}

function DeveloperSignIn({
	setFetchingTokens,
	setCredential
}: {
	setFetchingTokens: (fetchingTokens: boolean) => void
	setCredential: (value: TokenResponse | undefined) => void
}) {
	const handleClick = async () => {
		const tokenUrl = `https://${getApiUrl()}/authorize/developer`

		try {
			setFetchingTokens(true)
			const tokenResponse = await fetch(tokenUrl, {
				method: "POST"
			})

			if (!tokenResponse.ok) {
				toast.error("Failed to fetch tokens", toastOptions)
				throw new Error("Error getting token")
			}

			const tokens = (await tokenResponse.json()) as TokenResponse
			setCredential(tokens)
		} catch {
			toast.error(
				"Failed to fetch tokens. Is the API running?",
				toastOptions
			)
		} finally {
			setFetchingTokens(false)
		}
	}

	if (!zoneRegistry.isDevelopment()) {
		return null
	}

	return (
		<button onClick={handleClick} style={{ marginTop: 5 }}>
			Sign in as a developer
		</button>
	)
}

export function AuthenticationProvider({
	children
}: {
	children: JSX.Element | JSX.Element[]
}) {
	const { overriddenDate } = useSelector((state: State) => state.environment)
	const [initialised, setInitialised] = useState(false)
	const [fetchingTokens, setFetchingTokens] = useState(false)
	const [credential, setCredential] = useCredentialFromStorage()

	const credentialDetails = getCredentialDetails(credential?.access_token)

	const context: AuthenticationContext = {
		credentialDetails,
		logout: () => setCredential(undefined),
		createImpersonateLink: ({ userId, tenantSubDomain, baseUrl }) => {
			const query = new URLSearchParams()
			query.append("userId", userId)
			query.append("idToken", `${credential?.access_token}`)
			if (overriddenDate) {
				query.append("overriddenDate", `${overriddenDate}`)
			}
			query.append("tenantSubDomain", tenantSubDomain)

			return `${baseUrl}/impersonate?${query.toString()}`
		}
	}

	const handleCredentialResponse = useCallback(
		async (response: google.accounts.id.CredentialResponse) => {
			console.log("Signed in with Google. Fetching tokens")
			setFetchingTokens(true)

			try {
				const tokenUrl = `https://${getApiUrl()}/authorize/google`
				const body = new URLSearchParams()
				body.append("idtoken", response.credential)
				const tokenResponse = await fetch(tokenUrl, {
					method: "POST",
					body,
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
						"mco-tenant-subdomain": "mhg"
					}
				})

				if (!tokenResponse.ok) {
					toast.error(
						`Unable to get tokens. See console for logs`,
						toastOptions
					)
					const tokenResponseError = tokenResponse.text()
					console.error(`Unable to get tokens`, tokenResponseError)
					throw new Error("Error getting token")
				}

				const tokens = (await tokenResponse.json()) as TokenResponse
				setCredential(tokens)
			} catch (error) {
				toast.error(
					"Unable to get tokens. Is the API running?",
					toastOptions
				)
				console.error("Unable to get tokens:", error)
			} finally {
				console.debug("Finished fetching tokens")
				setFetchingTokens(false)
			}
		},
		[setCredential]
	)

	useLayoutEffect(() => {
		if (initialised) {
			return
		}

		console.debug("Intialising Google Authentication")
		google.accounts.id.initialize({
			client_id: googleSignInSettings.clientId,
			context: "signin",
			callback: handleCredentialResponse,
			hd: googleSignInSettings.workspaceDomain
		})

		document.addEventListener(unauthorizedApiRequestEventType, () => {
			console.warn(
				"An API request returned 401(Unauthorized) status. Logging current user out"
			)
			setCredential(undefined)
		})

		setInitialised(true)
	}, [handleCredentialResponse, initialised, setCredential])

	if (!initialised || fetchingTokens) {
		return <LoadingMessage />
	}

	if (!credentialDetails.isAuthenticated) {
		return (
			<SignInPage
				setFetchingTokens={setFetchingTokens}
				setCredential={setCredential}
			/>
		)
	}

	return <Context.Provider value={context}>{children}</Context.Provider>
}

function useAuthenticationContext() {
	return useContext(Context)
}

export default useAuthenticationContext
