import { Badge } from "react-bootstrap"
import {
	useIsTenantShutdown,
	useTenantEnvironment
} from "../tenancy/TenantHooks"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Icons from "../Icons"

const TenantShutdownBadge = () => {
	const isTenantShutdown = useIsTenantShutdown()
	const { isAnonymized } = useTenantEnvironment()
	return isTenantShutdown ? (
		<h2 className="mt-1 mx-1">
			<Badge variant="warning">
				<FontAwesomeIcon icon={Icons.Warning} className="mr-1" />
				Shutdown {isAnonymized && <span>(Anonymised)</span>}
			</Badge>
		</h2>
	) : null
}

export default TenantShutdownBadge
