import type { TokenResponse, GoogleDecodedIdToken } from "./AuthenticationTypes"
import { parseJwt } from "./parseJwt"
import { useLocalStorageState } from "./useLocalStorageState"

const credentialStorageKey = "authentication"

export const useCredentialFromStorage = () =>
	useLocalStorageState<TokenResponse>(credentialStorageKey)

export const getCredentialFromStorage = () => {
	const value = localStorage.getItem(credentialStorageKey)
	const json = value ? (JSON.parse(value) as TokenResponse) : undefined
	return json
}

const getDecodedCredentialFromStorage = () => {
	const credential = getCredentialFromStorage()

	if (!credential) {
		return undefined
	}

	const decodedCredential = parseJwt<GoogleDecodedIdToken>(
		credential.access_token
	)
	return decodedCredential
}

export const getUserEmailFromCredentialInStorage = () =>
	getDecodedCredentialFromStorage()?.email
