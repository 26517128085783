import * as React from "react"
import McoLogo from "../../images/mco-logo.svg?react"
import CenteredContainer from "./CenteredContainer"

const LoadingMessage = ({ children }: { children?: React.ReactNode }) => (
	<CenteredContainer>
		<McoLogo
			style={{
				height: "20vmin",
				pointerEvents: "none",
				animation: "pulse linear 2s infinite"
			}}
		/>
		<h1 className="h4">{children || "Loading, please wait..."}</h1>
	</CenteredContainer>
)

export default LoadingMessage
