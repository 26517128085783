import { Container, Row, Col } from "react-bootstrap"

import TopBar from "./components/layout/TopBar"
import LeftNav from "./components/layout/LeftNav"
import BrowserWarning from "./components/BrowserWarning"
import { useState } from "react"
import TenantProvider from "./components/tenancy/TenantProvider"
import AuthenticatedRoutes from "./components/layout/AuthenticatedRoutes"
import { useZoneIndicator } from "mco-zone-configurations/src/useZoneIndicator"

function App() {
	useZoneIndicator(true)

	const [showNav, setShowNav] = useState(true)
	const toggleNavVisibility = () => setShowNav(!showNav)

	return (
		<div className="App">
			<TenantProvider>
				<TopBar />
				<Container fluid>
					<Row>
						<LeftNav
							showNav={showNav}
							toggleNavVisibility={toggleNavVisibility}
						/>
						<Col
							className={`pr-0 ${
								showNav
									? "main-col-with-nav"
									: "main-col-no-nav"
							}`}
						>
							<BrowserWarning />
							<Container fluid>
								<AuthenticatedRoutes />
							</Container>
						</Col>
					</Row>
				</Container>
			</TenantProvider>
		</div>
	)
}

export default App
