import {
	useLocation,
	useNavigate,
	useParams,
	generatePath
} from "react-router-dom"
import TenantContext from "./TenantContext"
import { useCallback, useContext, useMemo } from "react"
import { DateTime } from "luxon"
import useToday from "../../hooks/useToday"
import {
	tenantRegistry,
	TenantOption as TenantConfigOption
} from "mco-tenant-configurations"

export const getTenantSubDomain = (locationPathname: string) => {
	const pathParts = locationPathname.split("/")
	const subDomain = pathParts.length > 1 ? pathParts[2] : undefined
	return subDomain
}

type TenantOption = TenantConfigOption & { disabled: boolean }

export const useTenantOptions = () => {
	const today = useToday()
	const tenantOptions: TenantOption[] = tenantRegistry
		.getTenantOptions()
		.map((tenantOption) => {
			const disabled =
				today > DateTime.fromISO(tenantOption.loginsDisabledAfter)

			return {
				...tenantOption,
				disabled
			}
		})
		.sort((x, y) => +x.disabled - +y.disabled)

	return tenantOptions
}

export const useTenantSubDomain = () => {
	const { tenantSubDomain } = useParams()

	if (!tenantSubDomain) {
		return undefined
	}

	return tenantRegistry.isSubDomain(tenantSubDomain)
		? tenantSubDomain
		: undefined
}

const patientProfessionalGuidRegExp = RegExp(
	"(^.*[patient|professional])/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}"
)

export const useSetTenantSubDomain = () => {
	const location = useLocation()
	const navigate = useNavigate()

	return useCallback(
		(tenantSubDomain: string) => {
			const path = generatePath("/tenant/:tenantSubDomain/*", {
				tenantSubDomain,
				"*": location.pathname
					.replace(patientProfessionalGuidRegExp, "$1/search")
					.substring(1)
			})

			navigate(path)
		},
		[location.pathname, navigate]
	)
}

export const useTenantSelector = () => {
	const location = useLocation()
	const navigate = useNavigate()
	const tenantSubDomain = useTenantSubDomain()

	return useCallback(() => {
		const path = generatePath("/*", {
			"*": location.pathname
				.replace(`/tenant/${tenantSubDomain}`, "")
				.substring(1)
		})
		navigate(path)
	}, [location, navigate, tenantSubDomain])
}

export const useTenantConfiguration = () => {
	const { tenant } = useContext(TenantContext)
	return tenant!.configuration
}

export const useTenantEnvironment = () => {
	const { tenant } = useContext(TenantContext)
	return tenant!.environment
}

export const useTenantSpecification = () => {
	const { tenant } = useContext(TenantContext)
	return tenant!.specification
}

export const useTenantPhinConditionKeys = () => {
	const ctx = useContext(TenantContext)
	const tenant = ctx.tenant!.configuration
	const phinConditionKeys = Object.keys(tenant.medicalHierarchy.conditions)
		.filter(
			(x: keyof typeof tenant.medicalHierarchy.conditions) =>
				tenant.medicalHierarchy.conditions[x].phinEnabled
		)
		.map(
			(x: keyof typeof tenant.medicalHierarchy.conditions) =>
				tenant.medicalHierarchy.conditions[x].conditionKey
		)
	return phinConditionKeys
}

export const useHasPhinConditions = () => {
	const ctx = useContext(TenantContext)
	const tenant = ctx.tenant!.configuration
	const phinConditionKeys = Object.keys(
		tenant.medicalHierarchy.conditions
	).find(
		(x: keyof typeof tenant.medicalHierarchy.conditions) =>
			tenant.medicalHierarchy.conditions[x].phinEnabled
	)

	return !!phinConditionKeys
}

export const useTenantConditionKeys = () => {
	const { tenant } = useContext(TenantContext)
	const {
		configuration: { medicalHierarchy }
	} = tenant!
	return Object.keys(medicalHierarchy.conditions).filter((x) =>
		x.startsWith("uri://")
	)
}

export const useTenantCondition = (conditionKey: string) => {
	const { tenant } = useContext(TenantContext)
	return tenant!.configuration.medicalHierarchy.conditions[conditionKey]
}

export const useIsTenantShutdown = (tenantOption?: TenantConfigOption) => {
	const ctx = useContext(TenantContext)
	const tenantOptions = useTenantOptions()

	if (!tenantOption) {
		tenantOption = tenantOptions.find(
			(x) => x.subDomain === ctx?.tenant?.configuration.subdomain
		)
	}

	if (!tenantOption) {
		throw Error("No tenant found")
	}

	const today = useToday()
	return useMemo(
		() => today > DateTime.fromISO(tenantOption.loginsDisabledAfter),
		[tenantOption.loginsDisabledAfter, today]
	)
}
