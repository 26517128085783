import { createRoot } from "react-dom/client"
import "bootstrap/dist/css/bootstrap.css"
import { createStore, applyMiddleware } from "redux"
import { Provider } from "react-redux"
import reducer from "./state/reducer"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import thunkMiddleware from "redux-thunk"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { apiMiddleware } from "redux-api-middleware"
import { applyLastOneWinsValidationMiddleware } from "./middleware/RequestValidationMiddleware"
import { applyTenantedApiRequestMiddleware } from "./middleware/ApiRequestMiddleware"
import { BrowserRouter } from "react-router-dom"
import { AuthenticationProvider } from "./components/authentication/AuthenticationContext"
import "./style/style.scss"

const loadState = () => {
	try {
		const serializedState = localStorage.getItem("state")
		if (serializedState === null) {
			return undefined
		}
		const savedState = JSON.parse(serializedState)
		return savedState
	} catch (err) {
		return undefined
	}
}

const saveState = (state: any) => {
	try {
		const stateToSave = { ...state }
		const serializedState = JSON.stringify(stateToSave)
		localStorage.setItem("state", serializedState)
	} catch {
		// ignore write errors
	}
}

const initialState = loadState()
let createStoreWithMiddleware = applyMiddleware(
	applyTenantedApiRequestMiddleware,
	applyLastOneWinsValidationMiddleware,
	apiMiddleware,
	thunkMiddleware
)(createStore)
const store = createStoreWithMiddleware(reducer(), initialState)

store.subscribe(() => {
	saveState({
		environment: {
			tenants: {}
		}
	})
})

const container = document.getElementById("root")
const root = createRoot(container!)
root.render(
	<Provider store={store}>
		<BrowserRouter>
			<ToastContainer />
			<AuthenticationProvider>
				<App />
			</AuthenticationProvider>
		</BrowserRouter>
	</Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
